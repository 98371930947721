import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Puzzle
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTkuNDM5IDcuODVjLS4wNDkuMzIyLjA1OS42NDguMjg5Ljg3OGwxLjU2OCAxLjU2OGMuNDcuNDcuNzA2IDEuMDg3LjcwNiAxLjcwNHMtLjIzNSAxLjIzMy0uNzA2IDEuNzA0bC0xLjYxMSAxLjYxMWEuOTguOTggMCAwIDEtLjgzNy4yNzZjLS40Ny0uMDctLjgwMi0uNDgtLjk2OC0uOTI1YTIuNTAxIDIuNTAxIDAgMSAwLTMuMjE0IDMuMjE0Yy40NDYuMTY2Ljg1NS40OTcuOTI1Ljk2OGEuOTc5Ljk3OSAwIDAgMS0uMjc2LjgzN2wtMS42MSAxLjYxYTIuNDA0IDIuNDA0IDAgMCAxLTEuNzA1LjcwNyAyLjQwMiAyLjQwMiAwIDAgMS0xLjcwNC0uNzA2bC0xLjU2OC0xLjU2OGExLjAyNiAxLjAyNiAwIDAgMC0uODc3LS4yOWMtLjQ5My4wNzQtLjg0LjUwNC0xLjAyLjk2OGEyLjUgMi41IDAgMSAxLTMuMjM3LTMuMjM3Yy40NjQtLjE4Ljg5NC0uNTI3Ljk2Ny0xLjAyYTEuMDI2IDEuMDI2IDAgMCAwLS4yODktLjg3N2wtMS41NjgtMS41NjhBMi40MDIgMi40MDIgMCAwIDEgMS45OTggMTJjMC0uNjE3LjIzNi0xLjIzNC43MDYtMS43MDRMNC4yMyA4Ljc3Yy4yNC0uMjQuNTgxLS4zNTMuOTE3LS4zMDMuNTE1LjA3Ny44NzcuNTI4IDEuMDczIDEuMDFhMi41IDIuNSAwIDEgMCAzLjI1OS0zLjI1OWMtLjQ4Mi0uMTk2LS45MzMtLjU1OC0xLjAxLTEuMDczLS4wNS0uMzM2LjA2Mi0uNjc2LjMwMy0uOTE3bDEuNTI1LTEuNTI1QTIuNDAyIDIuNDAyIDAgMCAxIDEyIDEuOTk4Yy42MTcgMCAxLjIzNC4yMzYgMS43MDQuNzA2bDEuNTY4IDEuNTY4Yy4yMy4yMy41NTYuMzM4Ljg3Ny4yOS40OTMtLjA3NC44NC0uNTA0IDEuMDItLjk2OGEyLjUgMi41IDAgMSAxIDMuMjM3IDMuMjM3Yy0uNDY0LjE4LS44OTQuNTI3LS45NjcgMS4wMloiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/puzzle
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const Puzzle = createLucideIcon('Puzzle', [
  [
    'path',
    {
      d: 'M19.439 7.85c-.049.322.059.648.289.878l1.568 1.568c.47.47.706 1.087.706 1.704s-.235 1.233-.706 1.704l-1.611 1.611a.98.98 0 0 1-.837.276c-.47-.07-.802-.48-.968-.925a2.501 2.501 0 1 0-3.214 3.214c.446.166.855.497.925.968a.979.979 0 0 1-.276.837l-1.61 1.61a2.404 2.404 0 0 1-1.705.707 2.402 2.402 0 0 1-1.704-.706l-1.568-1.568a1.026 1.026 0 0 0-.877-.29c-.493.074-.84.504-1.02.968a2.5 2.5 0 1 1-3.237-3.237c.464-.18.894-.527.967-1.02a1.026 1.026 0 0 0-.289-.877l-1.568-1.568A2.402 2.402 0 0 1 1.998 12c0-.617.236-1.234.706-1.704L4.23 8.77c.24-.24.581-.353.917-.303.515.077.877.528 1.073 1.01a2.5 2.5 0 1 0 3.259-3.259c-.482-.196-.933-.558-1.01-1.073-.05-.336.062-.676.303-.917l1.525-1.525A2.402 2.402 0 0 1 12 1.998c.617 0 1.234.236 1.704.706l1.568 1.568c.23.23.556.338.877.29.493-.074.84-.504 1.02-.968a2.5 2.5 0 1 1 3.237 3.237c-.464.18-.894.527-.967 1.02Z',
      key: 'i0oyt7',
    },
  ],
]);

export default Puzzle;
